$black1: #000000;

$white1: #ffffff;

$gray1: #333333;
$gray2: #4d4d4d;
$gray3: #666666;
$gray4: #999999;
$gray5: #cccccc;
$gray6: #ebebeb;

$red1: #e43f35;

$blue1: #1890ff;

$green1: #558935;

$orange1: #ea9e00;

// basic

$text-basic-color: $gray1;

$main-header-background: $gray1;

$page-header: $gray1;
$page-background: $gray6;

// components

$basic-card-border-color: $gray5;

$action-drawer-close-icon-color: $gray1;
$action-drawer-item-label-color: $gray3;
$action-drawer-item-bottom-border-color: $gray6;

$select-placeholder-color: $gray1;

$button-default-border-color: $gray4;

$card-background: $white1;
$card-shadows: #0000001a;

$fee-collapse-bottom-border-color: $gray5;
$fee-collapse-header-background-color: $gray6;
$fee-collapse-footer-color: $orange1;

$filter-button-background-color: $gray1;
$filter-button-text-color: $white1;

$reset-tabs-tab-default-color: $gray4;

// booking

$booking-status-accepted: $green1;
$booking-status-requesting: $orange1;

$booking-card-land-name: $gray1;
$booking-card-header-bottom-dashed-color: $gray5;
$booking-card-content-label: $gray4;

$batch-bookings-page-footer-background-color: $white1;
$batch-bookings-page-footer-reject-button-background-color: $gray6;

$check-booking-page-booking-page-land-name-background-color: $gray6;

$check-booking-page-footer-trademark-color: $white1;
$check-booking-page-footer-background-color: $gray1;

$check-booking-page-login-page-background-color: $gray6;
$check-booking-page-login-divider-color: $gray5;

$booking-detail-drawer-booking-detail-payable-total-color: $red1;

// land

$land-status-unlisted-color: $orange1;
$land-status-listed-color: $green1;
$land-status-suspension-color: $red1;

$land-create-drawer-step: $gray5;
$land-create-drawer-step-line: $gray5;
$land-create-drawer-step-full: $orange1;
$land-create-drawer-position-page-drag-map-description-color: $gray2;

$land-message-card-create-at-color: $gray4;

// rental

$rental-status-delivered-land: $green1;
$rental-status-disputed: $red1;
$rental-status-waiting-for-shipping: $orange1;

$parcel-detail-drawer-parcel-item-border-bottom-color: $gray6;

// payout

$payout-detail-subtitle-color: $gray2;

// calendar

$calendar-date-un-valid-background-color: $gray6;
$calendar-status-font-color: $white1;
$calendar-status-fee-background-color: #66ba33;
$calendar-status-booking-background-color: $orange1;
$calendar-status-un-available-background-color: $red1;
$calendar-status-final-background-color: $black1;
