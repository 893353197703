@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.land {
  width: 100%;
  font-size: 14px;
  color: $booking-card-land-name;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.card {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
}

.header {
  padding-bottom: 8px;
  border-bottom: 1px dashed $booking-card-header-bottom-dashed-color;
  display: flex;
  justify-content: space-between;
  align-content: center;

  .status {
    font-weight: 500;
  }
}

.content {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .label {
    color: $booking-card-content-label;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  .button {
    width: 100px;
    height: 40px;
  }
}

.select {
  width: 100%;
  height: 23px;
  position: relative;

  .checkBox {
    display: none;
  }

  .icon {
    padding: 10px;
    transform: translateY(-50%);
    position: absolute;
    right: 0px;
    top: 50%;
  }
}
