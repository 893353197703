@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 60px 16px 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  .label {
    padding-bottom: 8px;

    font-weight: 500;
  }
}
