@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: 100%;
  background-color: $check-booking-page-login-page-background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.space {
  flex-shrink: 1;
  flex-grow: 1;
}

.mainContent {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 60px 40px;
  max-width: 400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.title {
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .confirm {
    width: 100%;
    height: 40px;
  }
}

.divider {
  height: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after,
  &::before {
    content: '';
    display: block;
    width: 100px;
    border-top: 1px solid $check-booking-page-login-divider-color;
    position: absolute;
    top: 50%;
  }

  &::after {
    left: 0px;
  }

  &::before {
    right: 0px;
  }
}

.others {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  .button {
    border-radius: 20px;
  }
}
