@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.content {
  display: flex;
  flex-direction: column;

  .itemButton {
    padding: 0px 28px;
    width: 310px;
    height: 52px;
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .label {
    font-size: 20px;
    font-weight: 500;
  }
}
