@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.button {
  flex-shrink: 0;
  flex-grow: 0;
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.icon {
  width: 18px;
  height: 18px;
}
