@import '../../utils.scss';

.box {
  @include flex-column;
  background-color: $color_type_1;
  height: min-content;
  position: relative;
}
.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 45px;
  column-gap: 20px;
  width: 85.51%;
  @include xxs {
    row-gap: 28px;
    width: 92.31%;
    margin: 48px auto 72px;
  }
  position: relative;
  flex-grow: 1;
  max-width: 1440px;
  margin: 56px auto 88px;
  @include xl {
    width: 100%;
    margin: 32px 0px 80px;
  }
  @include lg {
    width: 91.84%;
    margin: 32px auto 72px;
  }
  @include md {
    width: 58.06%;
  }
  @include sm {
    width: 87.8%;
  }
  @include tn {
    margin: 48px auto 60px;
  }
}
.wrapper6 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 1 1 384px;
}
.wrapper6__item {
  @include flex-column;
  position: relative;
}
.icon {
  width: 40px;
  height: 40px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 40px;
  margin: 0px auto;
}
.wrapper5 {
  @include flex-column;
  position: relative;
  overflow: hidden;
  margin: 24px 0px 0px;
  @include xxs {
    margin: 16px 0px 0px;
  }
}
.col2 {
  @include flex-column;
  position: relative;
  flex-grow: 1;
  margin: 0px 16px;
}
.medium_title2 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_4;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 20px;
    text-align: center;
  }
  position: relative;
}
.highlights1 {
  @include h-center-content;
  font: $font_type_9;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
    margin: 8px 0px 0px;
  }
  @include tn {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
  @include md;
  @include sm {
    margin: 8px 54px 0px;
  }
  @include xs {
    margin: 8px 17px 0px;
  }
}
.wrapper4 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 1 1 384px;
}
.wrapper4__item {
  @include flex-column;
  position: relative;
}
.image2 {
  width: 40px;
  height: 38px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 40px;
  margin: 0px auto;
}
.medium_title21 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_3;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 20px;
    text-align: center;
  }
  position: relative;
}
.highlights11 {
  @include h-center-content;
  font: $font_type_9;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
    margin: 8px 0px 0px;
  }
  @include tn {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
  @include md;
  @include sm {
    margin: 8px 53px 0px;
  }
  @include xs {
    margin: 8px 11px 0px;
  }
}
.wrapper4__item1 {
  @include flex-column;
  position: relative;
}
.wrapper51 {
  @include flex-column;
  position: relative;
  overflow: hidden;
  margin: 24px 0px 0px;
  @include xxs {
    margin: 15px 0px 0px;
  }
}
.highlights12 {
  @include h-center-content;
  font: $font_type_10;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
  @include md;
  @include sm {
    margin: 8px 42px 0px;
  }
  @include xs {
    margin: 8px 0px 0px;
  }
}
.wrapper8 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 1 1 384px;
}
.wrapper8__item {
  @include flex-column;
  position: relative;
}
.wrapper9 {
  width: 40px;
  height: 40px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 40px;
  margin: 0px auto;
}
.highlights13 {
  @include h-center-content;
  font: $font_type_9;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
  @include md;
  @include sm {
    margin: 8px 15px 0px;
  }
  @include xs {
    margin: 8px 0px 0px;
  }
}
.wrapper7 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 1 1 384px;
}
.wrapper7__item {
  @include flex-column;
  position: relative;
}
.image3 {
  width: 40px;
  height: 32px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 40px;
  margin: 0px auto;
}
.highlights14 {
  @include h-center-content;
  font: $font_type_9;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
  @include md;
}
.wrapper10 {
  @include flex-column;
  border-radius: $border_radius_type_0;
  position: relative;
  flex: 1 1 384px;
}
.wrapper10__item {
  @include flex-column;
  position: relative;
}
.image6 {
  width: 44px;
  height: 32px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 44px;
  margin: 0px auto;
}
.highlights15 {
  @include h-center-content;
  font: $font_type_9;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include xxs {
    font-size: 14px;
    text-align: center;
  }
  @include tn {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
  @include md;
  @include sm {
    margin: 8px 44px 0px;
  }
  @include xs {
    margin: 8px 0px 0px;
  }
}
