@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description {
  flex-shrink: 0;
  flex-grow: 0;
}

.createButton {
  width: 100%;
  height: 40px;
}

.navigateButton {
  width: 100%;
  height: 40px;
}
