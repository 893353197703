@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 24px;
  height: 100%;
}

.header {
  padding-bottom: 42px;
}
