@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
}

.item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .label {
    font-size: 14px;
    color: $action-drawer-item-label-color;
  }

  .content {
    font-weight: 500;
  }
}

.divider {
  margin: 20px 0px;
  border-color: $action-drawer-item-bottom-border-color;
}
