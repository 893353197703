@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filters {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 20px;

  @include md {
    grid-template-columns: 1fr;
  }

  .land {
    flex-shrink: 1;
    flex-grow: 1;
  }

  .date {
    display: flex;
    align-items: center;
    gap: 20px;

    .month {
      flex-shrink: 0;
      flex-grow: 0;
      width: 200px;

      @include md {
        flex-shrink: 1;
        flex-grow: 1;
      }
    }

    .buttons {
      flex-shrink: 0;
      flex-grow: 0;
      display: flex;
      align-items: center;
      gap: 20px;

      .button {
        flex-shrink: 0;
        flex-grow: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.calendar {
  flex-shrink: 1;
  flex-grow: 1;
  overflow-y: auto;
}
