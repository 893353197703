@import '../../utils.scss';

.hero_title {
  @include h-center-content;
  font: $font_type_2;
  color: $color_type_5;
  text-align: center;
  letter-spacing: -1.2px;
  @include lg {
    font-size: 40px;
    text-align: center;
  }
  @include sm {
    font-size: 32px;
  }
  @include xxs {
    font-size: 28px;
  }
  position: relative;
}

.block21 {
  @include flex-column;
  background-color: $color_type_7;
}
.block21_layout {
  width: 100%;
  position: relative;
  flex-grow: 1;
}
.land_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-right: 102px;
  margin-left: 102px;
  margin-bottom: 64px;
}
.land_image_box {
  width: 360px;
  height: auto;
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 20px;
}
.land_image_layout {
  width: 100%;
  height: auto;
  filter: drop-shadow(0px 24px 48px rgba(17, 24, 39, 0.24705882352941178));
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  border-radius: 20px;
}
