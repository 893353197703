.countAbsolute {
  .ant-input-affix-wrapper {
    position: relative;
  }

  .ant-input-suffix {
    position: absolute;
    right: 0px;
    top: 100%;
  }
}
