@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.filter {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .label {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .statusSelect {
    width: 210px;
  }
}
.placeholder {
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 24px 0px;
  background-color: $batch-bookings-page-footer-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
