@import 'src/constants/styles/colors.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  height: fit-content;
}

.button {
  padding: 6px;
  transform: translateX(-6px);
}
