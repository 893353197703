@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .date {
    font-size: 14px;
    color: $land-message-card-create-at-color;
  }

  .content {
    line-height: 1.5;
  }
}
