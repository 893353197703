@import 'src/constants/styles/colors.scss';

.resetButton {
  &.ant-btn-default {
    border-color: $button-default-border-color;

    &:disabled {
      background-color: rgba(0, 0, 0, 0.04) !important;
      border-color: #d9d9d9d9 !important;
    }

    &.ant-btn-background-ghost {
      color: $text-basic-color;
    }

    &.ant-btn-dangerous {
      color: $red1;
    }

    &.ant-btn-text {
      &:disabled {
        border-color: transparent !important;
        background-color: transparent !important;
      }
    }
  }
}
