@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 16px;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  margin: 10px 0px;
  border-top: 1px dashed rgba(204, 204, 204, 1);
}

.description {
  width: 100%;
  height: 48px;

  line-height: 1.5;
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.footer {
  padding-top: 14px;
  display: flex;
  justify-content: flex-end;

  .detailButton {
    width: 100px;
    height: 40px;
    font-weight: 700;
  }
}
