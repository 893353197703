@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 16px;
  width: 100%;
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  gap: 12px;
  position: relative;

  .label {
    color: rgba(102, 102, 102, 1);
  }

  .content {
    font-size: 20px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1);
    word-break: break-all;
    text-wrap: wrap;
    text-align: left;
  }

  .edit {
    color: rgba(102, 102, 102, 1);
    display: flex;
    align-items: center;
    gap: 4px;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
