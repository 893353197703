@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.form {
  width: 100%;

  .datePicker {
    width: 100%;
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-weight: 500;
  }
}
