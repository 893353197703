@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .title {
    display: flex;
    align-items: center;
    gap: 16px;

    .icon {
      width: 22px;
      height: 22px;
      object-fit: contain;
    }

    .label {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .content {
    padding-left: 38px;
    font-size: 14px;
    text-align: left;
  }
}
