@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .content {
    height: 32px;
    font-size: 28px;
    font-weight: 600;
    color: #333333;
  }

  .subtitle {
    height: 18px;
    color: $payout-detail-subtitle-color;
  }
}
