// 共用, 每行容器.
.itemContainer {
  padding-bottom: 30px;

  &:last-child {
    padding-bottom: 0px;
  }
}

// 預設提示.
.infoContainer {
  padding-top: 6px;
}

// 共用.
.colorRed {
  color: #ff0000 !important;
}

.info1 {
  font-size: 14px;
  line-height: 20px;
  color: #8c8c8c;
}

// 預設提示.
.info2 {
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

// 錯誤提示.
.errorMessage {
  font-size: 14px;
  line-height: 20px;
  color: #ff0000;
}

.pdfLink {
  padding: 0px 4px;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

// 共用, 生日與性別選擇容器.
.heightItem {
  height: 60px;
}

// 撐滿容器.
.fullContainer {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
}

.firstNameBorder {
  border: 1px solid #8c8c8c;
  border-bottom-color: transparent;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.lastNameBorder {
  border: 1px solid #8c8c8c;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

// 生日.
.datePicker {
  border-radius: 5px;
}

// email 容器.
.emailContainer {
  .borderContainer {
    padding: 4px 12px;
    width: 100%;
    height: 60px;
    border: 1px solid #8c8c8c;
    border-radius: 5px;
  }

  .placeholder {
    font-size: 14px;
    color: #8c8c8c;
    font-weight: 500;
  }

  .email {
    font-size: 18px;
    color: #000000;
  }
}

// 通知容器.
.messagesContainer {
  width: 650px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info3 {
    padding-top: 6px;
    width: 360px;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}
