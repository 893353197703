@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 16px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #0000001a;
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}

.mainContent {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .label {
    padding-right: 20px;
    font-weight: 400;
    text-align: left;
    color: #666666;

    word-break: break-all;
    white-space: pre-wrap;
    text-wrap: wrap;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    &.hasNext {
      padding-right: 20px;
    }
  }

  .content {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    color: #333333;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    word-break: break-all;
    white-space: pre-wrap;
  }
}

.next {
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  right: 16px;
  top: 20px;
}
