@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 12px 0px;
  display: flex;
  flex-direction: column;

  .item {
    padding: 12px 24px;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
  }

  .icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  .label {
    font-size: 24px;
  }
}
