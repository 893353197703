@import '../../utils.scss';

.wrapper {
  @include flex-column;
  background: var(--src) 30% center / cover no-repeat;
  height: min-content;
  position: relative;
  overflow: hidden;
}

.col {
  @include flex-column;
  width: 93.89%;
  height: min-content;
  position: relative;
  margin: 26px auto 45px;
  @include xl {
    width: 94.41%;
    margin: 20px auto;
  }
  @include xxs {
    width: 94.28%;
  }
}
.row {
  display: flex;
  position: relative;
}
.row__item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 371px;
  @include xs {
    min-width: unset;
  }
}
.row1 {
  display: flex;
  width: fit-content;
  position: relative;
  flex-grow: 1;
  min-width: 0px;
}
.row1__item {
  @include flex-column;
  justify-content: center;
  position: relative;
  flex: 0 1 44px;
  @include xs;
}
.image1 {
  min-width: 29px;
  width: 29px;
  height: 44px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  @include xxs {
    min-width: 24px;
    width: 24px;
    height: 36px;
    margin: 0px;
  }
}
.row1__spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 57px;
  @include sm {
    flex: 0 1 auto;
    min-width: 27px;
  }
  @include xs {
    min-width: 19px;
  }
  @include xxs {
    min-width: 14px;
  }
}
.row1__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 270px;
}
.wrapper3 {
  display: flex;
  width: fit-content;
  position: relative;
  flex-grow: 1;
  min-width: 0px;
  margin: 21px 0px;
  @include md {
    margin: 20px 0px;
  }
}
.wrapper3__item {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 95px;
  text-decoration: none;
  @include sm {
    min-width: unset;
  }
}
.medium_title1 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_5;
  color: $color_type_7;
  text-align: center;
  letter-spacing: 0px;
  opacity: 0.6;
  margin: 0px;
  @include md {
    font-size: 20px;
    text-align: center;
  }
  @include sm {
  }
  @include xs {
    text-align: left;
  }
  @include xxs {
    font-size: 12px;
  }
  position: relative;
  flex-grow: 1;
}
.wrapper3__spacer {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 28px;
  @include tn {
    min-width: 16px;
  }
}
.wrapper3__item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 147px;
  @include sm {
    min-width: unset;
  }
}
.medium_title11 {
  @include v-center-content;
  justify-content: center;
  font: $font_type_5;
  color: $color_type_7;
  text-align: center;
  text-decoration: underline;
  letter-spacing: 0px;
  margin: 0px;
  @include md {
    font-size: 20px;
    text-align: center;
  }
  @include sm {
    margin: 0px;
  }
  @include xs {
    text-align: left;
  }
  @include xxs {
    font-size: 12px;
  }
  position: relative;
  flex-grow: 1;
}
.row__spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include xs;
}
.row__item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 130px;
  @include xxs {
    min-width: unset;
  }
}
.medium_title {
  @include v-center-content;
  justify-content: flex-end;
  font: $font_type_5;
  color: $color_type_0;
  text-align: right;
  letter-spacing: 0px;
  @include md {
    font-size: 20px;
    text-align: right;
    margin: 20px 0px;
  }
  @include sm {
  }
  @include xxs {
    font-size: 12px;
  }
  position: relative;
  flex-grow: 1;
  margin: 24px 0px 18px;
  cursor: pointer;
}
.col1 {
  @include flex-column;
  width: 72.12%;
  position: relative;
  margin: 138px 20.41% 0px 7.47%;
  @include lg {
    margin: 120px 20.41% 0px 7.47%;
  }
  @include sm {
    width: 83.55%;
    margin: 168px auto 0px;
  }
  @include xs {
    margin: 120px auto 0px;
  }
  @include xxs {
    margin: 36px auto 0px;
  }
  @include tn {
    margin: 80px auto 0px;
  }
}
.wrapper2 {
  @include flex-column;
  width: 45.04%;
  position: relative;
  overflow: hidden;
  margin: 0px 46.61% 0px 8.35%;
  @include xl {
    width: 54.39%;
    margin: 0px 45.61% 0px 0%;
  }
  @include lg {
    width: 69.27%;
    margin: 0px 30.73% 0px 0%;
  }
  @include md {
    width: 100%;
    margin: 0px 0px;
  }
}
.titles {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero_title1 {
  display: flex;
  text-align: center;
  @include font-face($font_type_0, $color_type_4, 0px);
  @include lg {
    font-size: 64px;
  }
  @include xs {
    font-size: 40px;
  }
  @include xxs {
    font-size: 36px;
  }
  position: relative;
}
.subtitle {
  display: flex;
  text-align: center;
  @include font-face($font_type_6, $color_type_4, 0px);
  @include sm {
    margin: 12px 0px 0px;
  }
  @include xs {
    text-align: center;
  }
  @include xxs {
    font-size: 12px;
  }
  position: relative;
  margin: 12px 0px 0px 0%;
}
.wrapper1 {
  @include flex-column;
  width: 82.05%;
  position: relative;
  overflow: hidden;
  margin: 387px 0% 0px 17.95%;
  @include lg {
    margin: 360px 0% 0px 17.95%;
  }
  @include md {
    margin: 400px 0% 0px 17.95%;
  }
  @include sm {
    width: 100%;
    margin: 316px 0px 0px;
  }
  @include xxs {
    margin: 189px 0px 0px;
  }
  @include tn {
    margin: 244px 0px 0px;
  }
}
.hero_title {
  @include h-center-content;
  font: $font_type_2;
  color: $color_type_5;
  text-align: center;
  letter-spacing: -1.2px;
  @include lg {
    font-size: 40px;
    text-align: center;
  }
  @include sm {
    font-size: 32px;
  }
  @include xxs {
    font-size: 28px;
  }
  position: relative;
}
.highlights {
  @include h-center-content;
  font: $font_type_8;
  color: $color_type_5;
  text-align: center;
  letter-spacing: 0px;
  @include sm {
    font-size: 14px;
    text-align: center;
  }
  @include xxs {
    font-size: 12px;
  }
  position: relative;
  margin: 8px 0px 0px;
}
