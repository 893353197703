@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 1px solid $parcel-detail-drawer-parcel-item-border-bottom-color;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.itemName {
  font-weight: 500;
  text-wrap: wrap;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .label {
    flex-shrink: 1;
    flex-grow: 1;

    font-weight: 500;
    color: rgba(153, 153, 153, 1);
  }

  .count {
    flex-shrink: 0;
    flex-grow: 0;

    font-weight: 500;
  }

  .status {
    flex-shrink: 0;
    flex-grow: 0;

    font-weight: 500;
  }
}

.footer {
  padding-top: 12px;
  display: flex;
  justify-content: center;
  gap: 40px;

  .button {
    width: 128px;
    height: 40px;
  }
}
