@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  .label {
    font-weight: 500;
  }

  .content {
    font-weight: 700;
  }
}

.description {
  font-size: 14px;
  line-height: 1.5;
  color: rgba(78, 78, 78, 0.85);
}
