.container {
  width: 100%;

  .button {
    width: 100%;
    height: 60px;
    border: none;
    box-shadow: none;
    background: linear-gradient(to bottom, #f1d261, #ffa318);
    color: #000000;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    transition: none;

    &.disabled {
      background: #8c8c8c;
      color: #ffffff;
    }

    &:not(.disabled):hover {
      background: linear-gradient(to bottom, #f1d261, #ffa318);
      color: #000000;
      opacity: 0.8;
    }
  }
}
