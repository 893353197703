@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page {
  flex-shrink: 1;
  flex-grow: 1;
  overflow-y: auto;
}

.footer {
  padding: 18px 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .button {
    width: 120px;
    height: 48px;
  }
}
