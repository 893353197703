@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding-left: 20px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #000000;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 10px;
    top: 50%;
  }
}
