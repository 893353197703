@import '../../utils.scss';

.box2 {
  @include flex-column;
  background-color: $color_type_7;
  position: relative;
}
.line {
  background-color: $color_type_5;
  height: 1px;
  opacity: 0.1;
  position: relative;
}
.row3 {
  display: flex;
  width: 83.18%;
  @include md {
    flex-wrap: wrap;
    align-content: center;
    column-gap: 0px;
    row-gap: 16px;
    width: 56.98%;
  }
  position: relative;
  margin: 24px auto;
  @include xl {
    width: 91.1%;
  }
  @include sm {
    width: 64.69%;
  }
  @include xxs {
    width: 82.7%;
  }
}
.row3__item {
  @include flex-column;
  position: relative;
  flex: 0 1 600px;
  @include md {
    flex: 0 0 100%;
  }
}
.row4 {
  display: flex;
  position: relative;
  flex-grow: 1;
  margin: 4px 0px;
  @include md {
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
    row-gap: 23px;
  }
  @include tn {
    @include flex-column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    row-gap: 0px;
  }
}
.text {
  display: flex;
  @include font-face($font_type_11, $color_type_3, 0px);
  @include md {
    text-align: center;
  }
  @include xxs {
    font-size: 12px;
  }
  position: relative;
  flex-grow: 1;
}
.row4__spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include xl {
    flex: 0 1 72px;
  }
  @include lg {
    flex: 0 1 52px;
  }
  @include md {
    display: none;
  }
  @include tn {
    display: flex;
    flex-basis: 20px;
  }
}
.row4__item {
  @include flex-column;
  position: relative;
  flex: 0 0 110px;
  @include md {
    flex-basis: calc(50% - 10px);
    align-items: center;
  }
  @include tn {
    flex-basis: auto;
  }
}
.row4__item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 95px;
  @include md {
    flex-basis: calc(50% - 10px);
    align-items: center;
  }
  @include tn {
    flex-basis: auto;
  }
}
.row4__item2 {
  @include flex-column;
  position: relative;
  @include md {
    flex-basis: calc(50% - 10px);
    align-items: center;
  }
  @include tn {
    flex-basis: auto;
  }
}
.row4__item3 {
  @include flex-column;
  position: relative;
  @include md {
    flex-basis: calc(50% - 10px);
    align-items: center;
  }
  @include tn {
    flex-basis: auto;
  }

  .text {
    word-break: break-all;
  }
}
.row3__spacer {
  @include flex-column;
  position: relative;
  flex: 1 1 auto;
  @include lg;
  @include md {
    display: none;
  }
}
.row3__item1 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 144px;
  @include md {
    flex: 0 0 100%;
    min-width: unset;
  }
}
.row5 {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  position: relative;
  flex-grow: 1;
  min-width: 0px;
  margin: 0px 0px;
  @include lg;
  @include md {
    margin: 0px auto;
  }
}
.row5__item {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;
}
.icon1 {
  width: 32px;
  height: 32px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 32px;
}
.row5__item1 {
  @include flex-column;
  position: relative;
  flex: 0 1 56px;
}
.image8 {
  width: 32px;
  height: 32px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 32px;
  margin: 0px 0px 0px 24px;
  @include md;
}
.image81 {
  width: 32px;
  height: 32px;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  min-width: 32px;
  margin: 0px 0px 0px 24px;
}
.row3__spacer1 {
  @include flex-column;
  position: relative;
  flex: 1 1 80px;
  @include lg {
    flex: 1 1 40px;
  }
  @include md {
    display: none;
  }
}
.row3__item2 {
  @include flex-column;
  position: relative;
  flex: 0 0 auto;
  min-width: 286px;
  @include md {
    flex: 0 0 100%;
    align-items: center;
    min-width: unset;
  }
}
.text1 {
  display: flex;
  justify-content: flex-end;
  font: $font_type_11;
  color: $color_type_2;
  text-align: right;
  letter-spacing: 0px;
  @include sm {
    justify-content: center;
    text-align: center;
  }
  @include xxs {
    font-size: 10px;
  }
  position: relative;
  flex-grow: 1;
  margin: 4px 0px;
}
