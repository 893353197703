.container {
  height: 500px;
  display: flex;
  flex-direction: column;

  .inputContainer {
    padding-bottom: 30px;

    &.showErrorMessage {
      padding-bottom: 50px;
    }
  }

  .itemContainer {
    padding-bottom: 10px;
  }

  .resetButton {
    margin-left: -16px;
    padding: 4px 16px;
  }
}
