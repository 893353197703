.container {
  width: 100%;
  height: 62px;
  border-bottom: 1px solid #8c8c8c;
  display: flex;
  align-items: center;

  .buttonContainer {
    width: 65px;
    height: 100%;

    .button {
      width: 100%;
      height: 100%;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .iconClose {
      width: 15px;
      height: 15px;
    }
  }

  .titleContainer {
    flex: 1;

    .title {
      font-size: 18px;
      color: #000000;
      text-align: center;
    }
  }
}
