@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .title {
    font-weight: 500;
    line-height: 1.6em;
  }

  .content {
    padding-left: 30px;
    line-height: 1.6em;
    text-indent: -1.8em;
  }
}
