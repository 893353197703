@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 48px;
  overflow-y: auto;

  .item {
    flex-shrink: 0;
    flex-grow: 0;

    .label {
      padding-bottom: 4px;

      font-weight: 500;
    }
  }

  .input {
    width: 100%;
  }
}

.readOnly {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .description {
    white-space: pre-wrap;
  }
}

.footer {
  flex-shrink: 0;
  flex-grow: 0;
  padding-top: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .button {
    width: 120px;
    height: 48px;
    font-weight: 500;
  }
}
