@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.mainLabel {
  flex-shrink: 0;
  flex-grow: 0;

  line-height: 1.5;
}

.add {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;

  .button {
    width: 100%;
    height: 50px;
    border: 5px;
  }
}

.table {
  flex-shrink: 1;
  flex-grow: 1;
  overflow-y: auto;

  .label {
    font-weight: 500;
  }

  .deleteButton {
    padding: 10px;
  }
}

.modalContainer {
  padding: 60px 16px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .item {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .label {
      font-weight: 500;
    }

    .date,
    .input {
      width: 100%;
    }
  }

  .footer {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 18px 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .button {
      width: 120px;
      height: 48px;
    }
  }
}
