@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  color: rgba(78, 78, 78, 0.85);
}

.upload {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  position: relative;

  .uploadButton {
    width: 120px;
    height: 42px;
  }

  .message {
    padding-top: 4px;
    color: rgba(255, 0, 0, 1);
    display: none;
    transform: translateX(-50%);
    position: absolute;
    left: 50%;
    top: 100%;

    &.show {
      display: block;
    }
  }
}

.table {
  flex-shrink: 1;
  flex-grow: 1;
  overflow: auto;

  .landPhoto {
    width: 100%;
    object-fit: contain;
    border-radius: 5px;
  }

  .deleteButton {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
