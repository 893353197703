@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 24px;
  width: 100%;
  height: 100%;
}

.form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-weight: 500;
  }
}

.inputNumber {
  width: 100%;
}
