@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 16px;
  background-color: $check-booking-page-footer-background-color;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
}

.trademark {
  color: $check-booking-page-footer-trademark-color;
}

.icons {
  display: flex;
  align-items: center;
  gap: 16px;
}
