@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px;
  position: relative;
}

.replyIcon {
  position: absolute;
  right: 16px;
  top: 20px;
}

.button {
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .createAt {
    font-size: 14px;
    color: $land-message-card-create-at-color;
  }

  .content {
    line-height: 1.5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
