@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 28px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

.photos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  gap: 13px;

  .photo {
    width: 100%;
    aspect-ratio: 3 / 2;
    position: relative;

    &.last::after {
      content: attr(data-leftover);
      display: block;
      width: 100%;
      height: 100%;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0px;
      top: 0px;
    }
  }

  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}
