@import 'src/constants/styles/colors.scss';

.actionDrawer {
  position: relative;

  .ant-drawer-header {
    padding: 20px;
    border-bottom: 0px;
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .ant-drawer-header-title {
    justify-content: flex-end;
  }

  .ant-drawer-close {
    margin: 0px;
    padding: 0px 10px;
    width: fit-content;
    font-size: 20px;
    color: $action-drawer-close-icon-color;
    background-color: transparent !important;
    transform: translateX(10px);
    display: flex;

    &:focus,
    &:hover {
      opacity: 0.6;
    }
  }
}
