@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.descriptionContainer {
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.uploadContainer {
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .upload {
    display: flex;
    justify-content: center;

    .button {
      width: 100px;
      height: 40px;

      font-weight: 600;
    }
  }

  .content {
    width: 100%;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;

    .idCard {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .uploadPlaceholder {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
