@import './constans.scss';

.calendarPage {
  .ant-picker-header {
    align-items: center;
  }

  .ant-picker-header-view {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  // 月曆

  .ant-picker-calendar {
    .ant-picker-body {
      padding: 8px 0px;
    }

    .ant-picker-content {
      // min-height: $calendarMinHeight;
    }
  }

  .ant-picker-cell {
    pointer-events: none;

    &.ant-picker-cell-in-view {
      pointer-events: auto;
    }
  }

  .ant-picker-cell-selected {
    background-color: #eeeeee;
  }
}
