@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.function {
  padding: 0px 20px;
  flex-shrink: 0;
  flex-grow: 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  @include md {
    grid-template-columns: 1fr;
  }

  .createButton {
    width: 210px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    @include md {
      width: 100%;
    }
  }

  .filter {
    width: 200px;
    height: 40px;
    justify-self: flex-end;

    @include md {
      width: 100%;
    }

    .select {
      width: 100%;
      height: 100%;
    }
  }
}

.cards {
  @include md {
    grid-template-columns: 1fr;
  }
}
