@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.desc {
  flex-shrink: 0;
  flex-grow: 0;
  padding-bottom: 16px;
  font-size: 14px;
  color: rgba(78, 78, 78, 0.85);
}

.table {
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .tableContent {
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.itemVariety {
  margin-left: -36px;
}
