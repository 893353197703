@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status {
  font-weight: 500;
}

.divider {
  margin: 0px;
  border-color: #cccccc;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;

  .label {
    flex-shrink: 0;
    flex-grow: 0;

    color: #999999;
  }

  .content {
    flex-shrink: 1;
    flex-grow: 1;

    color: #333333;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .button {
    width: 102px;
    height: 40px;

    font-weight: 600;
    color: #333333;
  }
}
