@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .step {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $land-create-drawer-step;
    position: relative;

    &.active {
      background-color: $land-create-drawer-step-full;
    }
  }

  &::before {
    width: 100%;
    height: 1px;
    background-color: $land-create-drawer-step-line;
    display: block;
    content: '';
    position: absolute;
    left: 0px;
    top: 8px;
  }
}
