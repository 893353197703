@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.filters {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 20px;
  justify-content: space-between;

  @include md {
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
  }

  .item1 {
    flex-shrink: 1;
    flex-grow: 1;

    .landsSelect {
      width: 100%;
    }
  }

  .item2 {
    flex-shrink: 0;
    flex-grow: 0;

    display: flex;
    gap: 20px;

    @include md {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }

  .statusSelect {
    flex-shrink: 0;
    flex-grow: 0;
    width: 210px;

    @include md {
      flex-shrink: 1;
      flex-grow: 1;
    }
  }
}
