@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .itemName {
    font-weight: 500;
  }

  .itemVarietyName {
    font-weight: 500;
    color: rgba(153, 153, 153, 1);
  }
}

.divider {
  margin: 10px 0px 0px;
  background-color: rgba(235, 235, 235, 1);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .label {
    font-weight: 500;
  }

  .inputNumber {
    width: 100%;
  }
}
