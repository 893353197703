@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';
@import '../../constans.scss';

$minHeight: 140px;

.main {
  min-height: $minHeight;
  width: 100%;
  position: relative;

  &.background {
    background-color: $calendar-date-un-valid-background-color;
  }
}

.container {
  min-height: $minHeight;
  width: 100%;

  border-radius: 0px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  @include xxs {
    padding: 4px 2px;
  }
}

.date {
  width: 100%;
  font-size: 12px;
  text-align: center;
}

.item {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 2px 8px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include sm {
    padding: 2px;
  }

  .content {
    font-size: 14px;

    @include sm {
      font-size: 12px;
    }

    @include xs {
      font-size: 10px;
    }
  }
}

.basicFee {
  color: $calendar-status-font-color;
  background-color: $calendar-status-fee-background-color;
}

.unAvailable {
  color: $calendar-status-font-color;
  background-color: $calendar-status-un-available-background-color;
}

.booking {
  color: $calendar-status-font-color;
  background-color: $calendar-status-booking-background-color;
}

.final {
  color: $calendar-status-font-color;
  background-color: $calendar-status-final-background-color;
}
