@import 'src/constants/styles/colors.scss';

.resetTabs {
  width: 100%;
  height: 100%;

  .ant-tabs-nav {
    margin: 0px;
  }

  .ant-tabs-tab {
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    flex-basis: 100px !important;
    margin: 0px !important;
    padding: 0px 0px 16px !important;
    display: flex;
    justify-content: center;
  }

  .ant-tabs-tab-btn {
    color: $reset-tabs-tab-default-color;
    font-weight: 700;
  }

  .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.tabFull {
  .ant-tabs-nav-list {
    width: 100%;
  }
}

.tabPadding {
  .ant-tabs-nav-list {
    padding: 0px 20px;
  }
}
