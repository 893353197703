.feeCollapse {
  .ant-collapse-header {
    padding: 0px !important;
    align-items: center !important;
    position: relative;
  }

  .ant-collapse-expand-icon {
    padding: 0px !important;
    height: fit-content !important;
    position: absolute;
  }

  .ant-collapse-arrow {
    font-size: 18px !important;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
}
