.resetInputNumber {
  .ant-input-number-input {
    min-height: 40px;
  }

  .ant-input-number-prefix {
    transform: translateY(1px);
  }
}

.customStepButton {
  .ant-input-number {
    position: relative;
  }

  .ant-input-number-disabled {
    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  .ant-input-number-handler-wrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    gap: 12px;
    transform: translateY(-50%);
    position: absolute;
    right: 12px;
    top: 50%;
  }

  // 以下是按鈕
  .ant-input-number-handler {
    width: 28px;
    height: 28px !important;
    border: none !important;
    border-radius: 0px !important;

    &:active {
      opacity: 0.5;
    }

    svg {
      display: none;
    }
  }

  .anticon-up,
  .anticon-down {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 0px;

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background-size: 28px 28px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .anticon-up::before {
    background-image: url('../../assets/icon_input_number_plus.svg');
  }

  .anticon-down::before {
    background-image: url('../../assets/icon_input_number_minus.svg');
  }

  .ant-input-number-handler-down-disabled .anticon-down::before {
    background-image: url('../../assets/icon_input_number_minus_disabled.svg');
  }
}
