@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollY {
  flex-grow: 1;
  flex-shrink: 1;
  padding: 0px 20px 20px;
  width: 100%;

  // 處理 height: 100%; 無法 overflow-y: auto; 的問題
  height: 0px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  overflow-y: auto;

  position: relative;
}

.totalsTable {
  flex-shrink: 0;
  flex-grow: 0;
}

.payoutsTable {
  flex-shrink: 1;
  flex-grow: 1;
}

.date {
  font-weight: 500;
}
