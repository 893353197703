@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

%row {
  padding: 7px 0px;
  width: 100%;
  min-height: 87px;
  border-bottom: 1px solid $fee-collapse-bottom-border-color;
  font-weight: 500;
  display: flex;
  align-items: center;
}

%item1 {
  flex-shrink: 1;
  flex-grow: 1;

  padding: 0px 12px;

  min-height: 100%;
  height: fit-content;

  word-break: break-all;
}

%item2 {
  flex-shrink: 0;
  flex-grow: 0;

  padding: 0px 12px;

  min-height: 100%;
  width: 120px;
  height: fit-content;

  word-break: break-all;

  display: flex;
  justify-content: center;
  align-items: center;
}

.headerRow {
  @extend %row;

  .item1 {
    @extend %item1;
    padding: 0px 12px 0px 40px;

    .full {
    }
  }

  .item2 {
    @extend %item2;
  }
}

.contentRow {
  @extend %row;

  .item1 {
    @extend %item1;
    padding: 0px 12px 0px 40px;
  }

  .item2 {
    @extend %item2;
  }
}

.content {
  // border-bottom: 1px solid $fee-collapse-bottom-border-color;
}
