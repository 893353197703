@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

$padding: 20px;

.container {
  flex-shrink: 1;
  flex-grow: 1;

  overflow-y: auto;
}

.cards {
  padding-left: $padding;
  padding-right: $padding;

  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: max-content;
  align-items: start;
  gap: 24px;

  @include xxxxl {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @include xxxl {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include xxl {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include lg {
    grid-template-columns: 1fr 1fr;
  }

  @include sm {
    grid-template-columns: 1fr;
  }
}

.paddingBottom {
  padding-bottom: $padding;
}
