.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
}

.font {
  font-size: 20px;
  font-weight: bold;
}
