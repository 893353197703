@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  gap: 18px;

  .label {
    font-size: 20px;
    font-weight: 500;
  }
}
