@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .label {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}

.footer {
  padding-bottom: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;

  .button {
    width: 120px;
    height: 48px;
  }
}
