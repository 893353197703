@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.create {
  padding: 0px 20px;
  flex-shrink: 0;
  flex-grow: 0;

  .button {
    width: 327px;
    height: 50px;
    font-weight: 700;

    @include xs {
      width: 100%;
    }
  }
}
