@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  max-height: 80vh;
  height: fit-content;
}

.content {
  font-size: 16px;
}

.terms {
  flex-shrink: 1;
  flex-grow: 1;
  padding-bottom: 2px;
  overflow-y: auto;
}

.form {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;

  .label {
    padding-left: 10px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .button {
    flex-shrink: 1;
    flex-grow: 1;
    max-width: 160px;
    height: 40px;
  }
}
