.container {
  position: relative;

  .codeInput {
    width: 100% !important;
    display: flex;
    justify-content: center;

    input {
      margin: 0px 20px;
      width: 60px !important;
      height: 60px !important;
      border: 1px solid lightgrey;
      border-radius: 10px !important;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;

      &:focus {
        outline: none;
        border: 2px solid #000000;
        caret-color: #000000;
      }
    }
  }

  .errorMessage {
    padding-top: 20px;
    width: 100%;
    font-size: 12px;
    text-align: center;
    color: #ff4d4f;
    position: absolute;
    top: 100%;
  }
}
