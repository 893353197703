#signUp {
  .ant-select-selector {
    height: 100%;
    border-radius: 5px;

    .ant-select-selection-search,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      font-size: 18px;
    }
  }

  .ant-picker-input {
    input {
      font-size: 18px;
    }
  }

  .ant-select-selection-placeholder {
    font-size: 18px;
    transform: translateY(-50%);
    position: absolute;
    left: 11px;
    top: 50%;
  }

  // switch checked.
  .ant-switch-checked {
    background-color: #f8bc3e;
  }
}
