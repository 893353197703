@import '../../../../utils.scss';

.container {
  display: none;

  &.small {
    display: block;

    @include md {
      display: none;
    }
  }

  &.big {
    @include md {
      display: block;
    }
  }

  .title {
    padding-bottom: 8px;
    font-size: 18px;
    font-weight: 500;
    color: #ffffff;

    @include lg {
      text-align: center;
    }
  }

  .buttons {
    display: flex;
    column-gap: 10px;

    @include lg {
      justify-content: center;
    }
  }

  .button {
    flex: 0 1 270px;
  }

  .image {
    width: 100%;
    object-fit: contain;
  }
}
