@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.drag {
  width: 32px;
  height: 32px;
  touch-action: none;
  cursor: move;

  .icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
