@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 20px 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;

  .cards {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer {
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.logout {
  padding-top: 10px;
  display: flex;
  justify-content: center;

  .button {
    width: 100px;
    height: 40px;

    font-weight: 600;
    color: rgba(153, 153, 153, 1);
  }
}

.deactivate {
  display: flex;
  justify-content: center;

  .button {
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
}

.underline {
  text-decoration: underline;
}
