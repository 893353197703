@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px 20px;
  width: 100%;
  height: 60px;
  background-color: $main-header-background;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 120px;
    height: 40px;
  }

  .buttons {
    display: flex;
    align-items: center;
    gap: 8px;

    .button {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
