@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 24px;
  height: 100%;
  overflow: auto;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.requestingButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .button {
    width: 132px;
    height: 32px;
  }
}

.divider {
  margin: 20px 0px;
  border-bottom: 1px solid rgba(227, 227, 227, 1);
}

.cancel {
  padding-top: 20px;

  .button {
    width: 100%;
    height: 50px;
    font-weight: 700;
  }
}

.prices {
  .space {
    width: 20px;
    height: 20px;
  }

  .payable {
    display: flex;
    align-items: center;

    .price {
      font-weight: 500;
      color: $booking-detail-drawer-booking-detail-payable-total-color;
    }
  }

  .payout {
    display: flex;
    align-items: center;

    .price {
      font-weight: 500;
    }
  }
}
