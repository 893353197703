@import '../styles/colors.scss';

.deliveredLand {
  color: $rental-status-delivered-land;
}

.disputed {
  color: $rental-status-disputed;
}

.waitingForShipping {
  color: $rental-status-waiting-for-shipping;
}
