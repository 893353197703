// 共用.
.fontSize1 {
}

// 共用.
.item {
  padding-bottom: 24px;

  &:last-child {
    padding-bottom: 0px;
  }
}

.container {
  display: flex;
  flex-direction: column;

  .mainInfoContainer {
    padding-bottom: 60px;
  }

  // 發送驗證信.
  .sendContainer {
    height: 310px;
  }

  // 重置密碼.
  .resetContainer {
    height: 310px;

    // 輸入驗證碼.
    .verifyCodeContainer {
      padding-bottom: 40px;
    }

    // 輸入密碼.
    .inputContainer {
      border: 1px solid #8c8c8c;
      border-radius: 5px;
    }
  }
}
