@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 262px;
  text-align: center;

  .button {
    margin: 0 auto;
  }
}
