$color_type_0: rgb(0, 0, 0);
$color_type_1: rgb(1, 1, 1);
$color_type_2: rgb(217, 219, 225);
$color_type_3: rgb(238, 239, 244);
$color_type_4: rgb(24, 25, 31);
$color_type_5: rgb(255, 255, 255);
$color_type_6: rgb(34, 34, 34);
$color_type_7: rgb(47, 47, 47);

$border_radius_type_0: 8px 8px 8px 8px;

$font_type_0: 700 72px/1.2 'Inter', Helvetica, Arial, serif;
$font_type_1: 700 48px/1.45 'Inter', Helvetica, Arial, serif;
$font_type_2: 700 48px/1.2 'Inter', Helvetica, Arial, serif;
$font_type_3: 700 24px/1.66 'Inter', Helvetica, Arial, serif;
$font_type_4: 700 24px/1.25 'Inter', Helvetica, Arial, serif;
$font_type_5: 700 24px/1 'Inter', Helvetica, Arial, serif;
$font_type_6: 400 20px/1.2 'Inter', Helvetica, Arial, serif;
$font_type_7: 400 18px/1.55 'Inter', Helvetica, Arial, serif;
$font_type_8: 400 18px/1.2 'Inter', Helvetica, Arial, serif;
$font_type_9: 400 16px/1.5 'Inter', Helvetica, Arial, serif;
$font_type_10: 16px/1.5 'Inter', Helvetica, Arial, serif;
$font_type_11: 400 14px/1.71 'Inter', Helvetica, Arial, serif;

@mixin tn {
  @media (max-width: 383px) {
    @content;
  }
}

@mixin xxs {
  @media (max-width: 479px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: 1399px) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: 1919px) {
    @content;
  }
}

@mixin xxxl {
  @media (max-width: 2999px) {
    @content;
  }
}

@mixin max {
  @media (max-width: 99999px) {
    @content;
  }
}

@mixin h-center-content {
  display: flex;
  justify-content: center;
}

@mixin v-center-content {
  display: flex;
  align-items: center;
}

@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-item($grow: 0, $shrink: 1, $basis: auto) {
  display: flex;
  position: relative;
  flex: $grow $shrink $basis;
}

@mixin font-face($font, $color, $letterSpacing: 0px) {
  font: $font;
  color: $color;
  letter-spacing: $letterSpacing;
}
