.resetTable {
  .ant-table-thead {
    .ant-table-cell {
      font-weight: 400;
    }
  }
}

.tHeadSticky {
  .ant-table-thead {
    position: sticky;
    top: 0px;
    z-index: 1;
  }
}
