@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  height: 35px;
  background-color: $fee-collapse-header-background-color;
  display: flex;
  align-items: center;
}

.item1 {
  flex-shrink: 1;
  flex-grow: 1;
  text-align: center;
}

.item2 {
  width: 120px;
  text-align: center;
}
