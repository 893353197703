@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  height: 87px;
  display: flex;
  align-items: center;
}

.item1 {
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0px 12px;
  font-weight: 800;
  text-align: left;
  color: $fee-collapse-footer-color;
}

.item2 {
  padding: 0px 12px;
  width: 120px;
  font-weight: 800;
  text-align: center;
  color: $fee-collapse-footer-color;
}
