@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-weight: 500;
  }
}
