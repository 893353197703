@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px 24px;
}

.steps {
  padding: 0px 24px;
}

.page {
  flex-shrink: 1;
  flex-grow: 1;
  overflow-y: auto;
}

.footer {
  flex-shrink: 0;
  flex-grow: 0;
  padding: 0px 24px;
  height: 80px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    .label {
      font-weight: 700;
    }
  }

  .back {
    width: 110px;
    height: 40px;
  }

  .next {
    width: 100px;
    height: 40px;
  }

  .done {
    width: 100px;
    height: 40px;
  }
}
