.container {
  display: flex;
  flex-direction: column;
  // align-items: center;

  // 共用.
  .itemContainer {
    padding-bottom: 30px;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  // 分隔線.
  .hr {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    &::after,
    &::before {
      content: '';
      display: block;
      width: 234px;
      height: 1px;
      background-color: #8c8c8c;
    }

    .hrText {
      flex: 1;

      color: #8c8c8c;
      text-align: center;
    }
  }

  // 外部登登入按鈕.
  .outsideLoginButton {
    width: 100%;
    height: 60px;
    border-radius: 20px;

    .flexContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buttonContent {
      font-size: 18px;
      font-weight: 500;
    }

    .iconContainer {
      width: 40px;
      height: 40px;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
