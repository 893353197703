@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 28px;
  font-weight: 600;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .itemLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    font-weight: 500;
  }

  .content {
    font-size: 14px;
    line-height: 16.5px;
    color: rgba(78, 78, 78, 0.85);
  }
}
