@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  height: 16px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.container {
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .status {
    display: flex;
    align-items: center;
    gap: 4px;

    .text {
      font-weight: 500;
    }

    .icon {
      width: 16px;
      height: 16px;
    }
  }

  .state {
    color: rgba(0, 0, 0, 0.7);
  }
}

.divider {
  border-top: 1px dotted rgba(204, 204, 204, 1);
}

.content {
  width: 100%;
  display: flex;
  gap: 12px;

  .photo {
    flex-shrink: 0;
    flex-grow: 0;
    width: 120px;
    height: 80px;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .counts {
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label {
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}

.footer {
  flex-shrink: 0;
  flex-grow: 0;
  padding-top: 4px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  .button {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 100px;
    max-width: 100px;
    height: 40px;
    overflow: hidden;
  }
}
