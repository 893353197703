@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: fit-content;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header {
  font-weight: 600;
  color: #333333;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .amount {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  .item {
    font-weight: 400;
    color: #333333;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }
}
