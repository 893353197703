@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 6px;

  .label {
    font-weight: 500;
  }

  .phoneInput {
    width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .button {
    width: 120px;
    height: 48px;

    font-weight: 600;
  }
}
