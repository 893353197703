@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 24px;
  width: 100%;
  height: 100%;
}

.createReply {
  padding-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
