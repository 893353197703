@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 16px;
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px $card-shadows;
  background-color: $card-background;
}

.border {
  border: 1px solid $basic-card-border-color;
}
