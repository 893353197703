@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
  }
}
