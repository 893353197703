.container {
  height: 500px;

  // 共用.
  .itemContainer {
    padding-bottom: 30px;
  }

  .verifyCodeContainer {
    padding-bottom: 40px;
  }

  .resendButtonContainer {
    display: flex;
    justify-content: center;
  }
}
