@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.space {
  flex-shrink: 1;
  flex-grow: 1;
}

.basicContainer {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0px auto;
  max-width: 600px;
  width: 100%;
}

.landName {
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  background-color: $check-booking-page-booking-page-land-name-background-color;
}

.section {
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .label {
    font-size: 18px;
    font-weight: 600;
  }

  .content {
    flex-shrink: 1;
    flex-grow: 1;
    font-size: 14px;
    text-align: right;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  .label {
    flex-shrink: 1;
    flex-grow: 1;
    font-size: 14px;
  }

  .content {
    flex-shrink: 1;
    flex-grow: 1;
    font-size: 14px;
    text-align: right;
  }
}

.requestingFooter {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  .button {
    width: 240px;
    height: 40px;
  }
}

.status {
  padding-bottom: 20px;
  text-align: center;

  .button {
    padding: 12px 24px;
  }
}
