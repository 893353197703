.page {
  background-color: #8c8c8c;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  .button {
    height: fit-content;
  }

  .content {
    font-size: 64px;
  }
}
