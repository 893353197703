@import 'src/constants/styles/colors.scss';

.unlisted {
  color: $land-status-unlisted-color;
}

.listed {
  color: $land-status-listed-color;
}

.suspension {
  color: $land-status-suspension-color;
}
