@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .button {
    width: 100px;
    height: 40px;
    font-weight: 600;
  }
}
