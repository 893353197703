@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description {
  width: 100%;
  height: 290px;
  font-size: 14px;
}

.form {
  width: 100%;
  height: 290px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .label {
      font-weight: 500;
    }
  }
}
