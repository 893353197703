@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  gap: 20px;
  position: relative;
}

.stripe {
  flex-shrink: 0;
  flex-grow: 0;
  position: absolute;
  right: 20px;
  top: 20px;

  .button {
    width: 210px;
    height: 40px;
  }

  @include xs {
    padding: 0px 20px;
    position: static;

    .button {
      width: 100%;
    }
  }
}

.tabs {
  flex-shrink: 1;
  flex-grow: 1;
  padding-top: 13px;
}
