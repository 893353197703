@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.form {
  padding: 0px 20px;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 20px;

  @include md {
    grid-template-columns: 1fr;
  }

  .landsSelect {
    max-width: 332px;
    width: 100%;

    @include md {
      max-width: 100%;
    }
  }

  .filters {
    display: flex;
    align-items: center;
    gap: 8px;

    .filterStatus {
      flex-shrink: 0;
      flex-grow: 0;
      width: 332px;

      @include md {
        flex-shrink: 1;
        flex-grow: 1;
        width: auto;
      }
    }
  }
}
