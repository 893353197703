@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.header {
  padding: 14px;
  position: absolute;
  right: 36px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    padding: 10px;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
