$xxs: 383px;
$xs: 479px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$xxxl: 1920px;
$xxxxl: 2560px;
$xxxxxl: 10000px;

@mixin xxs {
  @media (max-width: $xxs) {
    @content;
  }
}

@mixin xs {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: $md) {
    @content;
  }
}

@mixin lg {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin xl {
  @media (max-width: $xl) {
    @content;
  }
}

@mixin xxl {
  @media (max-width: $xxl) {
    @content;
  }
}

@mixin xxxl {
  @media (max-width: $xxxl) {
    @content;
  }
}

@mixin xxxxl {
  @media (max-width: $xxxxl) {
    @content;
  }
}

@mixin xxxxxl {
  @media (max-width: $xxxxxl) {
    @content;
  }
}
