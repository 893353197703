@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 0px 24px 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  .desc {
    padding-bottom: 16px;
    font-size: 14px;
    color: rgba(78, 78, 78, 0.85);
  }
}
