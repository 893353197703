@import '../../utils.scss';

.box1 {
  @include flex-column;
  background-color: $color_type_6;
  height: min-content;
  position: relative;
  overflow: hidden;
}

.row2 {
  display: flex;
  width: 84.44%;
  height: min-content;

  @include md {
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    column-gap: 0px;
    row-gap: 16px;
    width: 87.48%;
    margin: 68px auto;
  }

  position: relative;
  max-width: 1216px;
  margin: 109px auto 63px;

  @include xl {
    width: 91.02%;
    margin: 80px auto;
  }

  @include lg {
    width: 91.98%;
  }

  @include sm {
    margin: 64px auto;
  }

  @include xxs {
    margin: 48px auto;
  }
}

.row2__item {
  @include flex-column;
  position: relative;
  flex: 0 1 592px;

  @include md {
    flex: 0 0 100%;
  }
}

.wrapper12 {
  @include flex-column;
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  margin: 5px 0px 48px;

  @include lg {
    margin: 5px 0px 48px;
  }
}

.hero_title2 {
  display: flex;
  @include font-face($font_type_1, $color_type_5, -1.2px);

  @include lg {
    font-size: 40px;
    text-align: left;
  }

  @include md {
    text-align: center;
  }

  @include sm {
    font-size: 32px;
  }

  @include xxs {
    font-size: 24px;
  }

  @include tn {
    font-size: 20px;
  }

  position: relative;
}

.highlights2 {
  margin: 8px 0px 0px;
  display: flex;
  position: relative;

  @include font-face($font_type_7, $color_type_5, 0px);

  @include md {
    text-align: center;
  }

  @include sm {
  }

  @include xxs {
    font-size: 12px;
  }

  @include lg {
    margin: 16px 0px 0px;
  }
}

.row2__spacer {
  @include flex-column;
  position: relative;
  flex: 0 1 32px;

  @include md {
    display: none;
  }
}

.image7 {
  width: 100%;
  height: auto;
  vertical-align: top;
  object-fit: contain;
  object-position: center top;
  position: relative;
  flex-grow: 1;

  margin-top: 80px;

  @include md {
    width: calc(100% - 84px);
    margin: 32px auto 0px;
  }

  @include sm {
    margin: 32px auto 0px;
  }

  @include xxs {
    margin: 20px auto 0px;
  }
}
