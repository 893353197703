@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  width: 100%;
  height: 100%;
}

.dragMap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .description {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 20px;
    width: 100%;
    color: $land-create-drawer-position-page-drag-map-description-color;
  }

  .map {
    flex-shrink: 1;
    flex-grow: 1;
    width: 100%;
    position: relative;
  }

  .dragDesc {
    width: 284px;
    height: 30px;

    color: #ffffff;
    background-color: rgba(255, 163, 24, 1);
    border-radius: 20px;
    display: none;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, 10px);
    position: absolute;
    left: 50%;
    top: 50%;

    &.show {
      display: flex;
    }
  }
}

.inputLatLng {
  padding-top: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .inputContainer {
    flex-shrink: 0;
    flex-grow: 0;
    padding: 0px 24px;
    position: relative;
    z-index: 1;

    .label {
      padding-bottom: 4px;
      display: flex;
      align-items: center;
      gap: 4px;

      .content {
        font-weight: 500;
      }
    }
  }

  .mapContainer {
    flex-shrink: 1;
    flex-grow: 1;
    position: relative;
  }
}

.marker {
  width: 28px;
  height: 36px;
  transform: translate(-14px, -36px);
  position: absolute;
  left: 50%;
  top: 50%;
}
