@import 'src/constants/styles/colors.scss';

.button {
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0px;
  padding: 0px;
  width: fit-content;
  height: fit-content;
  font-weight: 600;
  display: block;
  justify-content: flex-start;
  align-items: start;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reject {
  background-color: $batch-bookings-page-footer-reject-button-background-color;
  border-color: transparent !important;

  &:disabled {
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: #d9d9d9d9 !important;
  }
}

.black {
  background-color: $filter-button-background-color;
  color: $filter-button-text-color;

  &:active,
  &:hover {
    background-color: $filter-button-background-color !important;
    color: $filter-button-text-color !important;
    opacity: 0.7 !important;
  }

  &:disabled {
    background-color: rgba(0, 0, 0, 0.04) !important;
    border-color: #d9d9d9d9 !important;
  }
}
