@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 60px 16px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.form {
  .item {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
