@import 'src/constants/styles/colors.scss';

.container {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: calc(100% - 60px);
  background-color: $page-background;
}
