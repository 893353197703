@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 24px;
  width: 100%;
  height: 100%;
  overflow-x: auto;
}

.address {
  text-align: left;
}

.item {
  max-width: 100%;
  width: 100%;

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  .label {
    font-weight: 500;
    text-align: left;
    color: rgba(153, 153, 153, 1);
  }

  .value {
    font-weight: 500;
    text-align: right;
  }

  .name {
    font-weight: 500;
    text-align: left;
  }

  .variety {
    flex-shrink: 1;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .count {
    flex-shrink: 0;
    flex-grow: 0;
  }
}
