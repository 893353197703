.container {
  padding: 0px 16px;
  width: 100%;
  height: 60px;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;

  &.border {
    border: 1px solid #8c8c8c;
  }

  .inputContainer {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

    .placeholder {
      font-size: 18px;
      color: #8c8c8c;
      transition: all 0.08s ease-in-out;
      transform: translate3d(0, 0, 0) scale(1);
      transform-origin: left center;
      position: absolute;
      z-index: 1;

      &.fold {
        transform: translate3d(1px, -36%, 0) scale(0.8);
        color: #8c8c8c;
      }
    }

    .input {
      width: 100%;
      font-size: 18px;
      background-color: transparent !important;
      border: none !important;
      outline: none !important;
      position: absolute;

      &.valid {
        padding-top: 22px;
      }
    }
  }

  .buttonContainer {
    .iconContainer {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;

        &.hide {
          transform: translateY(0.5px);
        }
      }
    }
  }

  .errorMessage {
    font-size: 14px;
    color: #ff0000;
    position: absolute;
    left: 0px;
    top: calc(100% + 2px);
  }
}
