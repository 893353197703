@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  display: flex;
  align-items: center;
  gap: 4px;

  .icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }

  .code {
    font-weight: 600;
  }
}
