@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0px 20px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .back {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .backButton {
    padding: 0px 12px;
    height: 40px;
  }

  .backIcon {
    width: 12px;
    color: $blue1;
  }

  .title {
    color: $page-header;
    font-size: 28px;
    font-weight: 600;
  }
}

.translateX {
  transform: translateX(-12px);
}
