@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 28px;
    font-weight: 600;
  }

  .buttons {
    flex-shrink: 0;
    flex-grow: 0;
    transform: translateX(10px);
    display: flex;
    align-items: center;
    gap: 0px;

    .button {
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.card {
  padding: 16px;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 12px;

  .label {
    font-weight: 400;
    text-align: left;
    color: #666666;

    word-break: break-all;
    white-space: pre-wrap;
  }

  .content {
    height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text {
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      color: #333333;

      word-break: break-all;
      white-space: pre-wrap;
    }
  }
}

.addTagButton {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
}
