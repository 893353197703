@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 28px;
  font-weight: 600;
}

.paddingBottom {
  padding-bottom: 42px;
}
