@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 20px 20px 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.item {
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    font-weight: 500;
  }
}

.footer {
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: end;

  .button {
    width: 160px;
    height: 48px;
    font-size: 20px;
    font-weight: 600;
  }
}
