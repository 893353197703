@import 'src/constants/styles/colors.scss';
@import 'src/constants/styles/media.scss';

.container {
  padding: 60px 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description {
  white-space: pre-line;
}

.footer {
  padding-top: 20px;
}
