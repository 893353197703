@import 'src/constants/styles/colors.scss';

.resetSelect {
  min-height: 40px;

  .ant-select-selector {
    min-height: 40px;
  }
}

.placeholderSolid {
  .ant-select-selection-placeholder {
    color: $select-placeholder-color;
  }
}
